import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import clx from "classnames";
import styled, { css } from "styled-components";
import {
  AppLogo,
  CloseFrame,
  CustomModal,
  SecuredByFooter,
  CompletedState,
  SnackBar,
  Banner,
} from "../component";
import { useStore } from "../store";
import { shallow } from "zustand/shallow";
import { useSpring, animated, AnimatedComponent } from "react-spring";

import { VisibilityTracker } from "../utils/visibiltyTracker";
import { RecoveryEmailWizard } from "../component/RecoveryEmailWizard";
import {
  PublicAppData,
  getAppTheme,
  getCachedAppDataByAppId,
} from "frontend-utils";
import {
  APP_HIDDEN,
  DARK_THEME,
  ENABLE_VISIBILITY_TRACKER,
  LIGHT_THEME,
  TABLET_WIDTH_BREAKPOINT,
} from "../constants";
import { logger } from "frontend-utils/logger";
import {
  FRAME_CSS_SELECTOR,
  LOGO_CSS_SELECTOR,
  ROOT_CSS_SELECTOR,
} from "../utils/cssUtils";
import { useScreenSize } from "../hooks";
import { CustomCss } from "../store/appSlice";

const MIN_HEIGHT = "250px";

interface FrameLayoutProps {
  render: (props: {
    visibilityTracker: VisibilityTracker | null;
  }) => JSX.Element;
}

const getCustomFrameContainerStyle = (
  cssObject: CustomCss[".frame-container"],
  applyCustomBorderRadius: boolean
) => {
  const customBackgroundColor = cssObject?.["background-color"];
  const customBorderRadius = cssObject?.["border-radius"];
  const customWidth = cssObject?.["width"];
  const customMinWidth = cssObject?.["min-width"];
  const customMaxWidth = cssObject?.["max-width"];

  return {
    ...(customBackgroundColor
      ? { "background-color": customBackgroundColor }
      : {}),
    ...(customBorderRadius && applyCustomBorderRadius
      ? { "border-radius": customBorderRadius }
      : {}),
    ...(customWidth ? { width: customWidth } : {}),
    ...(customMinWidth ? { "min-width": customMinWidth } : {}),
    ...(customMaxWidth ? { "max-width": customMaxWidth } : {}),
  };
};

const getCustomOverlayStyle = (cssObject: CustomCss[".root"]) => {
  const customBackgroundColor = cssObject?.["background-color"];
  const customAlignItems = cssObject?.["align-items"];

  return {
    ...(customBackgroundColor
      ? { "background-color": customBackgroundColor }
      : {}),
    ...(customAlignItems ? { "align-items": customAlignItems } : {}),
  };
};

const createStyledComponent = (
  Component: string | AnimatedComponent<"div">
) => styled(Component)<{ customStyles: any }>`
  ${(props) => props.customStyles}
  ${(props) =>
    props.customStyles.mediaQueries &&
    Object.entries(props.customStyles.mediaQueries).map(
      ([mediaQuery, styles]) => css`
        ${mediaQuery} {
          ${Object.entries(styles as { [key: string]: string })
            .map(([key, value]) => `${key}: ${value};`)
            .join(" ")}
        }
      `
    )}
`;

const FrameContainer = createStyledComponent("div");
const AnimatedFrameContainer = createStyledComponent(animated.div);
const CustomOverlayLayout = createStyledComponent("div");

export const FrameLayout = ({ render }: FrameLayoutProps) => {
  const {
    frameState,
    loading,
    snackbarState,
    isMobileApp,
    showRecoveryEmailWizard,
    appTheme,
    setAppTheme,
    appVisibility,
    appId,
    showApprovalBanner,
    customCss,
  } = useStore(
    (state) => ({
      frameState: state.frameState,
      loading: state.loading,
      snackbarState: state.snackbarState,
      isMobileApp: state.isMobileApp,
      widgetSlice: state.widgetSlice,
      showRecoveryEmailWizard: state.showRecoveryEmailWizard,
      appTheme: state.appTheme,
      setAppTheme: state.setAppTheme,
      appVisibility: state.appVisibility,
      appId: state.appId,
      showApprovalBanner: state.showApprovalBanner,
      customCss: state.customCss,
    }),
    shallow
  );

  const snackBarOpen = snackbarState.open;
  const [style, animate] = useSpring(
    () => ({ height: isMobileApp ? "100%" : MIN_HEIGHT }),
    []
  );
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const intersectionRef = useRef(null);
  const hideBackdrop = appVisibility === APP_HIDDEN;

  const { width: windowWidth } = useScreenSize();
  const [visibilityTracker, setVisibilityTracker] =
    useState<VisibilityTracker | null>(null);
  /**
   * ResizeObserver
   * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
   */
  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;
    const { current } = wrapperRef;
    // Specifically for Tablets
    if (isMobileApp && current.clientWidth >= TABLET_WIDTH_BREAKPOINT) {
      current.style.border = "1px solid rgba(238, 238, 238, 1)";
      current.style.boxShadow = "0px 3px 12px rgb(51 51 51 / 10%)";
      current.style.margin = "auto";
    }
    if (isMobileApp) return;
    const resizeObserver = new ResizeObserver(() => {
      // This acts as a listener for when the height of
      // the wrapperRef updates due to content change
      // update the height of the frame accordingly
      // we are animating a div on top of wrapper div
      const { height } = current.getBoundingClientRect();

      animate.start({
        height: `${height}px`,
      });
    });
    resizeObserver.observe(current);
    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, [animate, loading, isMobileApp]);

  const setupAppTheme = useCallback(() => {
    try {
      const cachedAppData = appId
        ? getCachedAppDataByAppId(appId)
        : ({} as PublicAppData);
      const currentTheme = getAppTheme(cachedAppData);
      setAppTheme(currentTheme);
    } catch (err) {
      logger.log(err);
      setAppTheme(LIGHT_THEME);
    }
  }, [setAppTheme, appId]);

  useEffect(() => {
    setupAppTheme();
  }, [setupAppTheme]);

  useEffect(() => {
    if (!intersectionRef || !intersectionRef.current) return;
    const { current } = intersectionRef;
    let currentTracker: VisibilityTracker | null = null;
    if (ENABLE_VISIBILITY_TRACKER) {
      currentTracker = new VisibilityTracker(current);
      setVisibilityTracker(currentTracker);
    }
    return () => {
      if (currentTracker) currentTracker.stop();
    };
  }, []);

  const frameChild = useMemo(() => {
    if (frameState) return <CompletedState />;
    const child = showRecoveryEmailWizard ? (
      <RecoveryEmailWizard />
    ) : (
      render({ visibilityTracker })
    );

    // Custom CSS for the app logo to hide it
    const logoCss = customCss?.[LOGO_CSS_SELECTOR] || {};
    const customLogoDisplay = logoCss["display"];
    const customLogoStyle = {
      ...(customLogoDisplay ? { display: customLogoDisplay } : {}),
    };

    return (
      <>
        <div style={customLogoStyle}>
          <AppLogo />
        </div>
        {child}
      </>
    );
  }, [
    frameState,
    render,
    showRecoveryEmailWizard,
    visibilityTracker,
    customCss,
  ]);

  // Custom CSS for the overlay layout
  const customOverlayLayoutStyles = useMemo(() => {
    const customOverlayLayoutCss = customCss?.[ROOT_CSS_SELECTOR] || {};
    const customOverlayStyle = getCustomOverlayStyle(customOverlayLayoutCss);

    // Handle media queries
    const customOverlayMediaQueries = Object.keys(customCss || {}).filter(
      (key) => key.startsWith("@media")
    );

    const customOverlayMediaQueryStyles = customOverlayMediaQueries.reduce(
      (acc, mediaQuery) => {
        const mediaQueryCss = customCss?.[mediaQuery] || {};
        const overlayMediaCss = mediaQueryCss[ROOT_CSS_SELECTOR] || {};
        const mediaCustomOverlayStyle = getCustomOverlayStyle(overlayMediaCss);

        acc[mediaQuery] = mediaCustomOverlayStyle;
        return acc;
      },
      {} as { [key: string]: { [key: string]: string } }
    );

    return {
      ...customOverlayStyle,
      mediaQueries: customOverlayMediaQueryStyles,
    };
  }, [customCss]);

  // Custom CSS for the frame container
  const customFrameContainerStyles = useMemo(() => {
    // for mobile devices the i.e assuming window width < 500px border radius is always 0px
    // for cases where screen size is greater than 500px we apply the custom border radius
    // this covers tablets and desktops
    const applyCustomBorderRadius =
      !isMobileApp || windowWidth >= TABLET_WIDTH_BREAKPOINT;

    const customFrameContainerCss = customCss?.[FRAME_CSS_SELECTOR] || {};
    const customFrameContainerStyle = getCustomFrameContainerStyle(
      customFrameContainerCss,
      applyCustomBorderRadius
    );

    // Handle media queries
    const customFrameContainerMediaQueries = Object.keys(
      customCss || {}
    ).filter((key) => key.startsWith("@media"));

    const customFrameContainerMediaQueryStyles =
      customFrameContainerMediaQueries.reduce((acc, mediaQuery) => {
        const mediaQueryCss = customCss?.[mediaQuery] || {};
        const frameContainerMediaCss = mediaQueryCss[FRAME_CSS_SELECTOR] || {};
        const mediaCustomContainerStyle = getCustomFrameContainerStyle(
          frameContainerMediaCss,
          applyCustomBorderRadius
        );

        acc[mediaQuery] = mediaCustomContainerStyle;
        return acc;
      }, {} as { [key: string]: { [key: string]: string } });

    return {
      ...customFrameContainerStyle,
      mediaQueries: customFrameContainerMediaQueryStyles,
    };
  }, [customCss, isMobileApp, windowWidth]);

  const overflowValue = snackBarOpen ? "unset" : "hidden";

  return (
    <CustomOverlayLayout
      className={clx("custom-overlay-layout", hideBackdrop && "transparent")}
      customStyles={customOverlayLayoutStyles}
    >
      <AnimatedFrameContainer
        style={{
          ...style,
          overflow: overflowValue,
          backgroundColor: appTheme === DARK_THEME ? "#272833" : "#fff",
          boxShadow: "0px 3px 12px rgb(51 51 51 / 10%)",
          borderRadius: isMobileApp ? "0px" : "18.75px",
          ...(isMobileApp && {
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }),
          visibility: appVisibility,
          position: "relative",
        }}
        // animated div has different sizes on different screen sizes
        // so we remove any unwanted custom css around the frame in case of mobile devices
        // Specifically used here to prevent unwanted css in case of Tablets
        customStyles={isMobileApp ? {} : customFrameContainerStyles}
        ref={intersectionRef}
        id="frame_layout"
      >
        <FrameContainer
          ref={wrapperRef}
          className={clx(
            "frame-wrapper",
            isMobileApp && "mobile-screen",
            appTheme === DARK_THEME && "dark-theme"
          )}
          customStyles={customFrameContainerStyles}
        >
          {showApprovalBanner && <Banner />}
          <div
            className={clx(
              "inner-wrapper",
              isMobileApp && "inner-wrapper-mobile-screen"
            )}
          >
            <CloseFrame />
            <CloseModal />
            {frameChild}
            <SecuredByFooter />
            <SnackBar />
          </div>
        </FrameContainer>
      </AnimatedFrameContainer>
    </CustomOverlayLayout>
  );
};

const CloseModal = () => {
  const showCloseModal = useStore((state) => state.showCloseModal);
  if (!showCloseModal) return null;
  return <CustomModal />;
};
