"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appSlice = exports.GET_WALLET_AUTH = exports.PROCESSING_LOADER_STATE = exports.AUTH_PROVIDER_STATE = void 0;
/******************************************* STORED SLICE DATA *******************************************/
var AUTH_PROVIDER_STATE;
(function (AUTH_PROVIDER_STATE) {
    AUTH_PROVIDER_STATE["ENABLED"] = "ENABLED";
    AUTH_PROVIDER_STATE["DISABLED"] = "DISABLED";
})(AUTH_PROVIDER_STATE || (exports.AUTH_PROVIDER_STATE = AUTH_PROVIDER_STATE = {}));
var PROCESSING_LOADER_STATE;
(function (PROCESSING_LOADER_STATE) {
    PROCESSING_LOADER_STATE["ENABLED"] = "ENABLED";
    PROCESSING_LOADER_STATE["DISABLED"] = "DISABLED";
})(PROCESSING_LOADER_STATE || (exports.PROCESSING_LOADER_STATE = PROCESSING_LOADER_STATE = {}));
var GET_WALLET_AUTH;
(function (GET_WALLET_AUTH) {
    GET_WALLET_AUTH["DEFAULT"] = "DEFAULT";
    GET_WALLET_AUTH["ENABLED_ALWAYS"] = "ENABLED_ALWAYS";
})(GET_WALLET_AUTH || (exports.GET_WALLET_AUTH = GET_WALLET_AUTH = {}));
const appSlice = (set) => ({
    displayName: null,
    appLogoUrl: null,
    secondaryColor: "",
    appId: null,
    skipFinalScreen: false,
    // Website Domain that's asking for an operations on user's wallets.
    // This can be empty if invoked using mobile sdks.
    requesterDomain: "",
    appTheme: null,
    userWallet: {
        processingLoader: PROCESSING_LOADER_STATE.ENABLED,
        getWalletAuth: GET_WALLET_AUTH.DEFAULT,
    },
    customCss: {},
    showApprovalBanner: false,
    setAppInfo: (payload) => set(() => (Object.assign({}, payload))),
    setRequesterDomain: (payload) => set(() => ({ requesterDomain: payload })),
    setAppId: (payload) => set(() => ({ appId: payload })),
    setAppTheme: (payload) => set(() => ({ appTheme: payload })),
    toggleApprovalBanner: (payload) => set(() => ({ showApprovalBanner: payload })),
});
exports.appSlice = appSlice;
